import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root',
})
export class AuthCookiesService {
    constructor(private cookieService: CookieService) {}

    // Method to set token in cookies
    setIdTokenCookie(idToken: string): void {
        const expiryDate = new Date();
        expiryDate.setHours(expiryDate.getHours() + 48); // Set the expiry time to 1 hour from now

        this.cookieService.set('idToken', idToken, {
            expires: expiryDate, // Correct way to set the expires
            path: '/',
            secure: true, // If you're using HTTPS
            sameSite: 'Strict', // Helps prevent CSRF attacks
        });
    }

    // Method to get token from cookies
    getIdTokenFromCookie(): string | null {
        return this.cookieService.get('idToken') || null;
    }

    // Method to remove token from cookies
    removeIdTokenCookie(): void {
        this.cookieService.delete('idToken');
    }
}
